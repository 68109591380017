import React, { useEffect } from "react";
import { Auth, Hub } from "aws-amplify";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const navigate = useNavigate();
  useEffect(() => {
    Hub.listen("auth", (event) => {
      if (event.payload.event === "signIn") {
        navigate("/dashboard");
      }
    });
  }, []);
  return (
    <div>
      <h1>Welcome to Data Dict site</h1>
      <span>
        To continue, please login using{" "}
        <div
          style={{
            textDecoration: "underline",
            display: "inline",
            cursor: "pointer",
            color: "skyblue",
          }}
          onClick={() => Auth.federatedSignIn({ provider: "Google" })}
        >
          Google
        </div>
      </span>
    </div>
  );
}
