import React from "react";
import { Link } from "react-router-dom";

export default function Unauthorized() {
  return (
    <div style={{ margin: "auto" }}>
      <h1>401</h1>
      <p>You are not authorized</p>
      <Link style={{ color: "skyblue" }} to="/">
        Go Back
      </Link>
    </div>
  );
}
