import { Auth } from "aws-amplify";
import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";

const WithAuthenticator = (props) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  const getCurrentUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      setUser(user);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to={{ pathname: "/unauthorized" }} replace />;
  }
  return props.children;
};

export default WithAuthenticator;
