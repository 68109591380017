const awsExports = {
  Auth: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_bfIUnYkLm',
    userPoolWebClientId: '6014vasih8m06hohb01gpt8e48',
    identityPoolId: 'us-east-1:441d672d-1db8-4f76-88f7-ff87943b0a11',
    mandatorySignIn: false,
    oauth: {
      domain: 'sso-test-2.auth.us-east-1.amazoncognito.com',
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: 'http://localhost:3000/',
      redirectSignOut: 'http://localhost:3000/',
      // redirectSignIn: 'https://sso-test.cloudfactorypre.app/',
      // redirectSignOut: 'https://sso-test.cloudfactorypre.app/',
      responseType: 'code',
    },
  },
  Storage: {
    AWSS3: {
      bucket: 'sso-test-method-2-shailendra',
      region: 'us-east-1',
    },
  },
};

export default awsExports;
