import { Storage } from 'aws-amplify';
import React, { useEffect, useState } from 'react';

export default function About() {
  const [content, setContent] = useState('');
  const getHTMLContent = async () => {
    await Storage.get('manifest.json', {
      download: true,
      contentType: 'application/json',
      expires: 3600,
    });
    await Storage.get('catalog.json', {
      download: true,
      contentType: 'application/json',
      expires: 3600,
    });
    const routeToProtect = await Storage.get('index.html', {
      download: true,
      contentType: 'text/html',
      expires: 3600,
    });
    setContent(await routeToProtect.Body.text());
  };

  useEffect(() => {
    getHTMLContent();
  }, []);

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <iframe
        title="dbt docs"
        srcDoc={content}
        height={'100%'}
        width={'100%'}
      ></iframe>
    </div>
  );
}
