import React from "react";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div style={{ margin: "auto" }}>
      <h1>404</h1>
      <p>Page not Found</p>
      <Link style={{ color: "skyblue" }} to="/">
        Go Back
      </Link>
    </div>
  );
}
