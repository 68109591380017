import "./App.css";
import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./component/Home";
import About from "./component/About";
import NotFound from "./component/404";
import Unauthorized from "./component/Unauthorized";
import WithAuthenticator from "./hoc/WithAuthenticator";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="dashboard"
            element={
              <WithAuthenticator>
                <About />
              </WithAuthenticator>
            }
          />
          <Route path="*" element={<NotFound />} />
          <Route path="unauthorized" element={<Unauthorized />} />
        </Routes>
      </header>
    </div>
  );
}

export default App;
